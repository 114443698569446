<template>
    <div>
        <header class="header header-light header-topbar header-topbar1 header-shadow" id="navbar-spy">
            <div class="top-bar">
                <div class="block-left">
                    <div class="top-contact">
                        <div class="contact-infos"><i class="fa fa-phone"></i>
                            <div class="contact-body">
                                <p>Tel: <a href="tel:+905324324408">+90 532 432 44 08</a></p>
                            </div>
                        </div>
                        <div class="contact-infos"><i class="fa fa-envelope"></i>
                            <div class="contact-body">
                                <p>Posta: <a href="info@eksperenerji.com"><span
                                            class="__cf_email__">info@eksperenerji.com</span> </a>
                                </p>
                            </div>
                        </div>
                        <div class="contact-infos"><i class="fa fa-shopping-bag"></i>
                            <div class="contact-body">
                                <a href="/enerjisepeti" style="cursor:pointer;"> Mağaza </a>
                            </div>
                        </div>
                        <div class="contact-infos"><i class="fa fa-map-marker"></i>
                            <div class="contact-body">
                                <p> Sakarya / Adapazarı </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block-right">

                    <div class="social-links">
                        <a style="cursor:pointer;" href="https://tr.linkedin.com/company/eksper-enerji">
                            <i class="pi pi-linkedin"></i>
                        </a>
                        <a style="cursor:pointer;" href="https://www.instagram.com/eksperenerji/">
                            <i class="pi pi-instagram"></i>
                        </a>
                    </div>

                    <div class="module module-language">
                        <div class="selected"><img src="../../../assets/images/module-language/tr.png"
                                alt="Türkçe Dili" /><span>Türkçe</span><i class="fas fa-chevron-down"></i></div>
                        <div class="lang-list">
                            <ul>
                                <li> <img src="../../../assets/images/module-language/tr.png" alt="TR Language" /><router-link to="/tr/Anasayfa">Türkçe</router-link></li>
                                <li> <img src="../../../assets/images/module-language/en.png" alt="TR Language" /><router-link to="/en/MainPage">English</router-link></li>
                                <!-- <li> <img src="assets/images/module-language/ar.png" alt="AR Language" /><a href="#">Arabic</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <nav class="navbar navbar-expand-lg navbar-sticky" id="primary-menu">
                <a class="navbar-brand" href="/tr/Anasayfa">
                    <img class="logo logo-dark" src="../../../assets/images/logo/logo-dark.png" alt="Eksper Enerji"  />
                    <img class="logo logo-mobile" src="../../../assets/images/logo/logo-mobile.png"
                        alt="Eksper Enerji" /></a>

                <div class="module-holder module-holder-phone">
                    <div class="module module-language">
                        <div class="selected"><img src="../../../assets/images/module-language/tr.png"
                                alt="TR Language" /><span>Türkçe</span><i class="fas fa-chevron-down"></i></div>
                        <div class="lang-list">
                            <ul>
                                <li> <img src="../../../assets/images/module-language/tr.png" alt="TR Language" /><router-link to="/tr/Anasayfa">Türkçe</router-link></li>
                                <li> <img src="../../../assets/images/module-language/en.png" alt="TR Language" /><router-link to="/en/MainPage">English</router-link></li>
                                <!-- <li> <img src="assets/images/module-language/ar.png" alt="AR Language" /><a href="#">Arabic</a></li> -->
                            </ul>
                        </div>
                    </div>
                    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false"
                        aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                </div>

                <div class="collapse navbar-collapse" id="navbarContent">
                    <ul class="navbar-nav me-auto">
                        <li class="nav-item" id="contact" data-hover=""><router-link to="/tr/Anasayfa"><span>Ana Sayfa</span></router-link></li>
                        <li class="nav-item" id="contact" data-hover=""><router-link to="/tr/SirketProfil"><span>Şirket Profili</span></router-link></li>
                        <li class="nav-item" id="contact" data-hover=""><router-link to="/tr/Projeler/BagEvi_HobiEvi"><span>Projeler</span></router-link></li>
                        <li class="nav-item" id="contact" data-hover=""><router-link to="/tr/Hizmetler/SebekedenBagimsizSistemler"><span>Hizmetler</span></router-link></li>
                        <!-- <li class="nav-item" id="contact" data-hover=""><router-link to="/tr/iletisim"><span>İletişim</span></router-link></li> -->
                        <li class="nav-item" id="contact" data-hover=""><router-link to="/tr/Blog"><span>Blog</span></router-link></li>
                    </ul>
                    <div class="module-holder">
                    </div>
                </div>
            </nav>
        </header>
    </div>
</template>

<script>
export default {
    name: "Navbar",
    mounted() {

    },
};
</script>

<style scoped>.brandLogo {
    height: 140%;
    width: 100%;
}</style>
