<template lang="">
  <div id="footer">
    <footer class="footer footer-1">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-3 col-md-3 col-lg-3">
              <div class="footer-widget widget-contact">
                <div class="widget-content">
                  <div class="footer-widget-title">
                    <img
                      src="../../../assets/images/logo/logo-dark.png"
                      style="width: 12vw"
                    />
                  </div>
                  <div class="address">
                    <p>
                      Eksper enerji olarak, solar sistemler üzerine uzmanlaşmış; danışmanlık,
projelendirme, planlama, mühendislik uygulama ile işletme ve bakım
alanlarında faaliyet gösteren, anahtar teslim çözümler üreten dinamik ve
tecrübeli bir firmayız.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 col-md-3 col-lg-3 ">
              <div class="footer-widget widget-contact">
                <div class="widget-content">
                    <div class="gt_footerheader ml-4">
                  <h5 style="color: white">Hızlı Erişim</h5>
                </div>
                  <div>
                    <ul class="ml-4" >
                      <li class="gt_footermenu" style="list-style-type: circle;">
                        <router-link to="/tr/SirketProfil">Şirket Profili </router-link>
                      </li>
                      <li class="gt_footermenu" style="list-style-type: circle;">
                        <router-link to="/tr/Blog">Blog</router-link>
                      </li>
                      <li class="gt_footermenu" style="list-style-type: circle;">
                        <router-link to="/tr/Projeler/BagEvi_HobiEvi">Projelerimiz</router-link>
                      </li>
                      <li class="gt_footermenu" style="list-style-type: circle;">
                        <router-link to="/tr/YediAdim">Yedi Adım</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 col-md-3 col-lg-3">

                <div class="footer-widget widget-contact">
                <div class="widget-content">
                    <div class="gt_footerheader">
                  <h5 style="color: white">Hizmetlerimiz</h5>
                </div>
                  <div>
                    <ul>
                      <li class="gt_footermenu" style="list-style-type: circle;">
                        <router-link to="/tr/Hizmetler/SebekeyeBagliSistemler">Şebekeye Bağlı Sistemler (On-Grid)</router-link>
                      </li>
                      <li class="gt_footermenu" style="list-style-type: circle;">
                        <router-link to="/tr/Hizmetler/SebekedenBagimsizSistemler">Şebekeden Bağımsız Sistemler (Off-Grid)</router-link>
                      </li>
                      <li class="gt_footermenu" style="list-style-type: circle;">
                        <router-link to="/tr/Hizmetler/GunesEnerjisiileTarimsalSulamaSistemleri">Tarımsal Sulama Sistemleri</router-link>
                      </li>
                      <li class="gt_footermenu" style="list-style-type: circle;">
                        <router-link to="/tr/Hizmetler/HibritGunesEnerjisiSistemleri">On Grid Hybrid Güneş Enerjisi Sistemleri</router-link>
                      </li>
                      <li class="gt_footermenu" style="list-style-type: circle;">
                        <router-link to="/tr/Hizmetler/ElektrikliAracSarjIstasyonu">Elektrikli Araç Şarj İstasyonu</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-3 col-md-3 col-lg-3">
              <div class="footer-widget widget-contact">
                <div class="widget-content">
                  <div class="footer-widget-title">
                    <h5>Diğer İletişim Bilgilerimiz</h5>
                  </div>
                  <ul>
                    <li class="phone">
                      <a href="tel:+905324324408">+90 532 432 44 08</a>
                    </li>
                    <li class="email">
                      Email:
                      <a href="mailto:info@eksperenerji.com"
                        ><span
                          class="__cf_email__"
                          data-cfemail="info@eksperenerji.com"
                          >info@eksperenerji.com</span
                        ></a
                      >
                    </li>
                    <li class="address">
                      <p>
                        Hızırtepe Mah Fatih Cd No: 37/10 Adapazarı / SAKARYA
                      </p>
                    </li>
                    <li class="directions">
                      <a href="https://goo.gl/maps/hPkFRy9q4eQZykh27"
                        ><i class="energia-location-Icon"></i>Yol Tarifi Alın</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="footer-copyright">
                <div class="copyright">
                  <span
                    >&copy; 2023 by<a href="https://gelsinteknik.com"
                      >GelsinTeknik</a
                    >. Tüm Hakları Saklıdır
                  </span>
                  <ul class="list-unstyled social-icons">
                    <li>
                      <a style="cursor:pointer;" href="https://tr.linkedin.com/company/eksper-enerji">
                            <i class="pi pi-linkedin"></i>
                        </a>
                    </li>
                    <li>
                      <a style="cursor:pointer;" href="https://www.instagram.com/eksperenerji/">
                            <i class="pi pi-instagram"></i>
                        </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div
      style="
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        margin: 25px 25px 25px 25px;
      "
      id="back-to-top"
    >
      <i class="energia-arrow-up"></i>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
